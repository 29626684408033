export const environment = {
  production: false,
  authDomain: 'https://vip-dev.linkroom.com/__/auth/handler',
  api: 'https://vip-admin-api-dev.linkroom.com',
  clientLink: 'https://fxp-dev.linkroom.com',
  apiLinkroom: 'https://admindev-api.linkroom.com',
  experience: 'https://linkroomv3dev.web.app',
  firebase: {
    projectId: 'linkroom-v2-dev',
    appId: '1:383066649579:web:19c4ead8fa9b62525a1fae',
    storageBucket: 'linkroom-v2-dev.appspot.com',
    locationId: 'us-east1',
    apiKey: 'AIzaSyAmHvWxoIoLv41qJVXeIGlo6Y-Jbn_K6DI',
    authDomain: 'vip-dev.linkroom.com',
    messagingSenderId: '383066649579',
    measurementId: 'G-5HW1Y5YHWW'
  },
  imagekit: {
    publicKey: 'public_HaXtq7gDdr1WTjhEhsMpoKTpp+8=',
    urlEndpoint: 'https://ik.imagekit.io/b152q4ah5/linkroom-dev'
  },
  commemorative_photo_url: 'https://us-central1-linkroom-v2-dev.cloudfunctions.net/api',
  // AGORA_APP_ID: 'e44f21f13d6248ee99ad12e7d12b19ec',
  AGORA_APP_ID: 'b41999bfe8494271a9577b3bfcc2e08b',
//   PHOTON_APP_ID: '29fe702e-40fd-4f02-b3c6-246835f618af',
  PHOTON_APP_ID: '80c3db96-6a0d-4ef3-8de4-3a197adfac00',
  stripe: {
    pk: 'pk_test_51MwSWlG8SGFDayonaMDGntqMTtH9Cupr3Z59yLLIhPkYzJjiJrW1hseyAIr6VJMAI8UxPzxcrF8JXumfBnj4ufeN00e9mxM41Y'
  }
};
